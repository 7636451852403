import { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import GlobalToast from '@/components/common/design-system/GlobalToast'
import GlobalToastContainer from '@/containers/GlobalToastContainer'

type MainProps = {
  className?: string
  background?: string
}

const MainBody = ({ className, children, background = 'bg-white' }: PropsWithChildren<MainProps>) => {
  const { toastControl } = GlobalToastContainer.useContainer()
  return (
    <motion.main
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.3 }}
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
      }}
      id="main-body"
      className={classNames('h-full min-h-screen w-full max-w-body', background, className)}>
      <div id="modal" className="fixed bottom-0 z-dim" />
      <div id="bottom-sheet" className="fixed bottom-0 z-dim" />
      <div id="toast" className="fixed bottom-0 z-toast" />
      <div id="announcement" className="fixed bottom-0 z-announcement w-full max-w-body" />
      <div id="loading" className="fixed bottom-0 z-loading" />
      <div id="pullToRefresh" className="fixed bottom-0 z-loading"></div>
      {children}
      <GlobalToast toastControls={toastControl} />
    </motion.main>
  )
}

export default MainBody
