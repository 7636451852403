import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { ToastControlsType } from '@/hooks/common/useToast'

type GlobalToastProps = {
  className?: string
  toastControls: ToastControlsType
}

/**
 * TODO: sprint 배포 후 리네임
 * - 기존 Toast -> LegacyToast
 * - GlobalToast -> Toast
 * - GlobalToastContainer -> ToastContainer
 * - useToast는 유지, 가능하다면 LegacyToast 제거 후 쿼리파람 코드 제거
 */
const GlobalToast = ({ toastControls, className = 'bottom-100' }: GlobalToastProps) => {
  const { toastListData, removeToastById } = toastControls
  return (
    <section
      className={classNames(
        'absolute mb-[var(--safe-area-inset-bottom)] flex h-fit w-screen max-w-body flex-col items-center gap-26',
        className
      )}>
      <AnimatePresence initial={true}>
        {toastListData?.map((toastItem) => {
          return (
            <motion.li
              key={toastItem.id}
              className="body3 flex min-h-48 w-[calc(100%-3.2rem)] cursor-pointer rounded-xs bg-black/80 px-12 py-14 font-medium text-white"
              layout
              initial={{ opacity: 0.5, y: 24 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 12 }}
              transition={{ ease: 'easeInOut' }}
              onClick={() => removeToastById(toastItem.id)}>
              {toastItem.content}
            </motion.li>
          )
        })}
      </AnimatePresence>
    </section>
  )
}

export default GlobalToast
